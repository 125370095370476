import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Flex, Grid, Group, Image, LoadingOverlay, NumberInput, Select, SimpleGrid, Switch, Text, TextInput, Textarea, Tooltip, rem } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconCircleMinus, IconPhoto, IconUpload, IconX } from "@tabler/icons-react";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { DatePickerInput } from "@mantine/dates";
import { validation } from "../../../plugins/validation";
import { addCoupon, updateCoupon } from "../../../services/coupon";
import { notificationError, notificationSuccess } from "../../ui/Notification";
import { getListMemberLevel } from "../../../services/memberLevel";
import http from "../../../plugins/axios";
import { NumericFormatCustom } from "../../../plugins/helper";

const defaultVal = {
  name: "",
  description: "",
  pointAmount: "",
  image: "",
  code: "",
  startDate: new Date(),
  endDate: new Date(),
  memberLevelId: "",
  isOnce: true,
  isActive: false,
  quantity: "",
  quantityLimit: "1",
  discountPercent: "",
  discountRp: "",
};

const formValidation = {
  name: {
    isError: false,
    message: "",
  },
  description: {
    isError: false,
    message: "",
  },
  pointAmount: {
    isError: false,
    message: "",
  },
  image: {
    isError: false,
    message: "",
  },
  code: {
    isError: false,
    message: "",
  },
  startDate: {
    isError: false,
    message: "",
  },
  endDate: {
    isError: false,
    message: "",
  },
  memberLevelId: {
    isError: false,
    message: "",
  },
  isOnce: {
    isError: false,
    message: "",
  },
  isActive: {
    isError: false,
    message: "",
  },
  quantity: {
    isError: false,
    message: "",
  },
  quantityLimit: {
    isError: false,
    message: "",
  },
  discountPercent: {
    isError: false,
    message: "",
  },
  discountRp: {
    isError: false,
    message: "",
  },
};

const FormKupon = ({ onCloseModal, dataCoupon, couponList }) => {
  const isSmallScreen = useMediaQuery("(max-width: 625px)");
  const [loadingForm, setLoadingForm] = useState(false);
  const [formData, setFormData] = useState(defaultVal);
  const [validationForm, setValidationForm] = useState(formValidation);
  const [rejectFileMessage, setRejectFileMessage] = useState("");
  const [selectedMemberLevelId, setSelectedMemberLevelId] = useState("");
  const [memberLevelList, setMemberLevelList] = useState([]);
  const [errorFile, setErrorFile] = useState("");
  console.log(validationForm);
  console.log(formData);
  // console.log(dataCoupon);

  const handleGetListMemberLevel = async () => {
    setLoadingForm(true);
    try {
      const response = await getListMemberLevel();
      const dataMemberLevel = response.data;
      const mappingMemberLevel = [
        { value: "", label: "Select level member" },
        ...dataMemberLevel.map((val) => ({
          value: val?.id?.toString(),
          label: val?.name,
        })),
      ];
      setMemberLevelList(mappingMemberLevel);
    } catch (error) {
      console.log(error);
    }
    setLoadingForm(false);
  };

  useEffect(() => {
    handleGetListMemberLevel();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dataCoupon !== null) {
      handleSetForm(dataCoupon);
    }
    // eslint-disable-next-line
  }, [dataCoupon]);

  const handleSetForm = async (dataCoupon) => {
    setLoadingForm(true);
    let file = null;
    if (dataCoupon?.image !== null) {
      try {
        const response = await http.get(`/public/${dataCoupon?.image}`, {
          responseType: "blob",
        });
        file = new File([response.data], dataCoupon?.image);
      } catch (error) {
        console.log(error);
        setErrorFile(error.response.statusText);
      }
    }
    const dataStartDate = new Date(dataCoupon.startDate);
    const tglStart = new Date(dataCoupon.startDate).getDate();
    dataStartDate.setDate(tglStart);
    const dataEndDate = new Date(dataCoupon.endDate);
    const tglEnd = new Date(dataCoupon.endDate).getDate();
    dataEndDate.setDate(tglEnd);
    const dataDetail = {
      image: file !== null ? file : null,
      name: dataCoupon.name,
      description: dataCoupon.description,
      pointAmount: parseInt(dataCoupon.pointAmount),
      code: dataCoupon.code,
      startDate: dataStartDate,
      endDate: dataEndDate,
      memberLevelId: selectedMemberLevelId,
      isOnce: Boolean(dataCoupon?.isOnce),
      isActive: Boolean(dataCoupon?.isActive),
      quantity: parseInt(dataCoupon.quantity),
      quantityLimit: parseInt(dataCoupon.quantityLimit),
      discountRp: parseInt(dataCoupon.discountRp),
      discountPercent: parseInt(dataCoupon.discountPercent),
    };
    setSelectedMemberLevelId(dataCoupon?.memberLevel?.id.toString());
    setFormData(dataDetail);
    setLoadingForm(false);
  };

  const previews = useMemo(() => {
    if (formData.image) {
      const imageUrl = URL.createObjectURL(formData.image);
      return <Image key={imageUrl} src={imageUrl} onLoad={() => URL.revokeObjectURL(imageUrl)} alt="Preview" width={100} height={100} />;
    }
    return null;
  }, [formData.image]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDateChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleDrop = useCallback((files) => {
    if (files.length > 0) {
      setFormData((prevState) => ({ ...prevState, image: files[0] }));
      setRejectFileMessage("");
    }
  }, []);

  const handleRejectFile = useCallback((files) => {
    const errMessage = files[0].errors[0].message;
    setRejectFileMessage(errMessage);
    setFormData((prevState) => ({ ...prevState, image: "" }));
  }, []);

  const handleSwitchActive = () => {
    setFormData({ ...formData, isActive: !formData.isActive });
  };

  const handleSwitchSekaliPakai = () => {
    const newIsOnce = !formData.isOnce;
    setFormData({
      ...formData,
      isOnce: newIsOnce,
      quantityLimit: newIsOnce ? "1" : formData.quantityLimit,
    });
  };

  // const handleSwitchSekaliPakai = () => {
  //   setFormData({ ...formData, isOnce: !formData.isOnce });
  //   if (formData.isOnce === true) {
  //     setFormData({ ...formData, quantityLimit: "1" });
  //   }
  // };

  const handleSubmit = async (data) => {
    let methodFunction = null;
    let titleMessageSuccess = "";
    let captionMessageSuccess = "";
    let titleMessageError = "";
    let captionMessageError = "";
    setLoadingForm(true);
    setValidationForm(formValidation);
    const payload = {
      name: data.name,
      description: data.description,
      pointAmount: parseInt(data.pointAmount),
      image: data.image,
      code: data.code,
      startDate: data.startDate,
      endDate: data.endDate,
      memberLevelId: selectedMemberLevelId,
      isOnce: Boolean(data.isOnce),
      isActive: Boolean(data.isActive),
      quantity: data.quantity,
      // discountRp: data.discountRp,
      // discountPercent: data.discountPercent,
    };
    if (!data.isOnce) {
      payload.quantityLimit = data.quantityLimit;
    }
    if (data.isOnce) {
      payload.quantityLimit = parseInt(data.quantityLimit);
    }
    if (!dataCoupon) {
      payload.quantityLimit = data.quantityLimit;
    }
    if (formData.discountRp !== "") {
      payload.discountRp = data.discountRp;
    }
    if (formData.discountPercent !== "") {
      payload.discountPercent = data.discountPercent;
    }
    const isError = validation(payload, setValidationForm);
    if (isError) {
      setLoadingForm(false);
      return;
    }
    if (dataCoupon !== null) {
      methodFunction = updateCoupon(dataCoupon?.id, payload);
      titleMessageSuccess = "Update Kupon Berhasil";
      captionMessageSuccess = "Anda telah berhasil mengupdate data Kupon";
      titleMessageError = "Gagal Mengupdate Kupon";
      captionMessageError = "Silahkan cek kembali form anda";
    } else {
      methodFunction = addCoupon(payload);
      titleMessageSuccess = "Tambah Kupon Berhasil";
      captionMessageSuccess = "Anda telah berhasil menambahkan Kupon baru";
      titleMessageError = "Gagal Menambahkan Kupon";
      captionMessageError = "Silahkan cek kembali form anda";
    }
    try {
      const response = await methodFunction;
      if (response) {
        onCloseModal();
        couponList();
        notificationSuccess(titleMessageSuccess, captionMessageSuccess);
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      notificationError(titleMessageError, `${Object.keys(errorMessage) ? errorMessage : captionMessageError}`);
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el,
              },
            }));
          }
        });
      });
    } finally {
      setLoadingForm(false);
    }
  };

  return (
    <Box p={isSmallScreen ? "sm" : 0}>
      <Box pos="relative">
        <LoadingOverlay visible={loadingForm} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
        <Box mb={70}>
          <Box mb="md">
            <TextInput radius="sm" name="name" value={formData.name} label="Nama Kupon" placeholder="Masukkan nama kupon" error={validationForm.name.isError ? `${validationForm.name.message}` : ""} onChange={handleChange} withAsterisk />
          </Box>
          <Box mb="md">
            <TextInput
              disabled={dataCoupon ? true : false}
              description={dataCoupon ? "Form tidak bisa diedit" : ""}
              radius="sm"
              name="code"
              value={formData.code}
              label="Kode Kupon"
              placeholder="Masukkan kode kupon"
              error={validationForm.code.isError ? `${validationForm.code.message}` : ""}
              onChange={handleChange}
              withAsterisk
            />
          </Box>
          <Box mb="md">
            <Grid>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <DatePickerInput
                  disabled={dataCoupon ? true : false}
                  description={dataCoupon ? "Form tidak bisa diedit" : ""}
                  value={formData.startDate}
                  valueFormat="DD MMM YYYY"
                  name="startDate"
                  placeholder="Tanggal dimulai"
                  label="Tanggal Dimulai"
                  onChange={(value) => handleDateChange("startDate", value)}
                  withAsterisk
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <DatePickerInput
                  mt={dataCoupon ? "lg" : "0px"}
                  value={formData.endDate}
                  valueFormat="DD MMM YYYY"
                  name="endDate"
                  placeholder="Tanggal berakhir"
                  label="Tanggal Berakhir"
                  onChange={(value) => handleDateChange("endDate", value)}
                  withAsterisk
                />
              </Grid.Col>
            </Grid>
          </Box>
          <Box mb="md">
            <Select
              mr={8}
              name="memberLevelId"
              label="Level Member"
              withAsterisk
              placeholder="Select level member"
              searchable
              nothingFoundMessage="Level member tidak ditemukan"
              data={memberLevelList}
              onChange={(e) => setSelectedMemberLevelId(e)}
              value={selectedMemberLevelId}
            />
          </Box>
          <Box mb="md">
            <TextInput
              disabled={dataCoupon ? true : false}
              description={dataCoupon ? "Form tidak bisa diedit" : ""}
              radius="sm"
              name="pointAmount"
              value={formData.pointAmount}
              label="Poin"
              placeholder="Masukkan jumlah poin yang ditukarkan"
              error={validationForm.pointAmount.isError ? `${validationForm.pointAmount.message}` : ""}
              onChange={handleChange}
              withAsterisk
            />
          </Box>
          <Box mb="md">
            <Grid>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <TextInput
                  disabled={dataCoupon ? true : false}
                  description={dataCoupon ? "Form tidak bisa diedit" : ""}
                  component={NumericFormatCustom}
                  radius="sm"
                  name="discountRp"
                  value={formData.discountRp}
                  label="Jumlah Diskon (Rp)"
                  placeholder="Masukkan jumlah diskon rupiah"
                  error={validationForm.discountRp.isError ? `${validationForm.discountRp.message}` : ""}
                  onChange={handleChange}
                  withAsterisk
                />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <NumberInput
                  disabled={dataCoupon ? true : false}
                  description={dataCoupon ? "Form tidak bisa diedit" : ""}
                  radius="sm"
                  rightSection="%"
                  name="discountPercent"
                  value={formData.discountPercent}
                  label="Jumlah Diskon (%)"
                  placeholder="Masukkan jumlah diskon persen"
                  error={validationForm.discountPercent.isError ? `${validationForm.discountPercent.message}` : ""}
                  onChange={(value) => handleChange({ target: { value, name: "discountPercent" } })}
                  withAsterisk
                />
              </Grid.Col>
            </Grid>
          </Box>
          <Box mb="md">
            <NumberInput
              radius="sm"
              value={formData.quantity}
              label="Kuota Kupon"
              placeholder="Masukkan kuota kupon yang dapat digunakan"
              error={validationForm.quantity.isError ? `${validationForm.quantity.message}` : ""}
              onChange={(value) => handleChange({ target: { value, name: "quantity" } })}
              withAsterisk
            />
          </Box>
          <Box mb="md">
            <Flex justify="flex-start">
              <Text fz={14} mx={3} mr={8}>
                Sekali pakai :{" "}
              </Text>
              <Tooltip label="Atur penukaran kupon" refProp="rootRef">
                <Switch description="apabila off Anda dapat mengatur jumlah penukaran" color="#152766" onLabel="YES" offLabel="NO" checked={formData.isOnce} onChange={handleSwitchSekaliPakai} />
              </Tooltip>
            </Flex>
          </Box>
          {!formData.isOnce && (
            <Box mb="md">
              <NumberInput
                radius="sm"
                value={formData.quantityLimit}
                label="Max penukaran"
                placeholder="Masukkan maksimal penukaran kupon"
                error={validationForm.quantityLimit.isError ? `${validationForm.quantityLimit.message}` : ""}
                onChange={(value) => handleChange({ target: { value, name: "quantityLimit" } })}
                withAsterisk
              />
            </Box>
          )}

          <Box mb="md">
            <Flex justify="flex-start">
              <Text fz={14} mx={3} mr={8}>
                Active :{" "}
              </Text>
              <Switch color="#152766" onLabel="YES" offLabel="NO" checked={formData.isActive} onChange={handleSwitchActive} />
            </Flex>
          </Box>
          <Textarea
            radius="sm"
            mb="md"
            name="description"
            label="Deskripsi"
            placeholder="Masukkan deskripsi kupon"
            value={formData.description}
            error={validationForm.description.isError ? `${validationForm.description.message}` : ""}
            autosize
            minRows={3}
            maxRows={4}
            onChange={handleChange}
            withAsterisk
          />
          <Box mb="md">
            <Text fz={14} mx={3} mr={8}>
              Gambar Kupon
            </Text>

            <Dropzone maxSize={1 * 1024 ** 2} accept={IMAGE_MIME_TYPE} onDrop={handleDrop} onReject={handleRejectFile} style={{ border: "2px dashed #ACB2B8", borderRadius: "10px", cursor: "pointer" }}>
              <Group justify="center" gap="xl" mih={120} style={{ pointerEvents: "none" }}>
                <Dropzone.Accept>
                  <IconUpload style={{ width: rem(52), height: rem(52), color: "var(--mantine-color-blue-6)" }} stroke={1.5} />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <IconX style={{ width: rem(52), height: rem(52), color: "var(--mantine-color-red-6)" }} stroke={1.5} />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  <IconPhoto style={{ width: rem(52), height: rem(52), color: "var(--mantine-color-dimmed)" }} stroke={1.5} />
                </Dropzone.Idle>

                <div>
                  <Text size={isSmallScreen ? "sm" : "lg"} px={isSmallScreen ? "md" : "sm"} inline>
                    Seret gambar ke sini atau klik untuk memilih file
                  </Text>
                  <Text size={isSmallScreen ? "xs" : "sm"} px={isSmallScreen ? "md" : "sm"} c="dimmed" inline mt={isSmallScreen ? 4 : 7} mb={isSmallScreen ? 8 : 0}>
                    Lampirkan gambar, gambar tidak boleh melebihi 1MB
                  </Text>
                </div>
              </Group>
            </Dropzone>
            {rejectFileMessage !== "" && (
              <Text fz={14} mx={3} mr={8} c="red" mt="sm">
                {rejectFileMessage}
              </Text>
            )}
            {errorFile !== "" && formData.image === null && (
              <Text fz={14} mx={3} mr={8} c="red" mt="sm">
                Image {errorFile}
              </Text>
            )}
            <SimpleGrid cols={{ base: 1, sm: 4 }} mt={formData.image ? "md" : 0}>
              <Box sx={{ position: "relative", display: "inline-block" }}>
                {previews}{" "}
                {formData.image !== null && formData.image !== "" && (
                  <IconCircleMinus
                    size={24}
                    color="red"
                    style={{
                      position: "absolute",
                      bottom: "5.5rem",
                      left: "8rem",
                      cursor: "pointer",
                    }}
                    onClick={() => setFormData((prevState) => ({ ...prevState, image: "" }))}
                  />
                )}
              </Box>
            </SimpleGrid>
          </Box>
        </Box>
      </Box>

      <Box style={{ position: "fixed", bottom: 0, left: 0, width: "100%", background: "white", padding: "20px", zIndex: 1000 }}>
        <Flex justify="flex-end">
          <Group>
            <Button variant="outline" color="#152766" onClick={onCloseModal}>
              Tutup
            </Button>
            <Button variant="filled" color="#152766" onClick={() => handleSubmit(formData)} loading={loadingForm}>
              Simpan
            </Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  );
};

export default FormKupon;
